.canban {
    margin-bottom: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 35px;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    min-height: calc(100vh - 310px);
    .canban-list-wrapper {
        box-sizing: border-box;
        display: inline-block;
        height: 100%;
        margin: 0 6px;
        scroll-margin: 8px;
        vertical-align: top;
        white-space: nowrap;
        width: 272px;
        .btn-add{
            background-color: rgba($orange,0.15);
            font-size: 14px;
            padding:14px 16px;
            border-radius: 12px;
            &:hover{
                background-color: rgba($orange,0.35);
            }
        }
        .canban-list {
            background-color: white;
            border-radius: 12px;
            box-shadow: $shadow-default;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            max-height: 100%;
            position: relative;
            white-space: normal;
            width: 100% !important;
            &-header {
                flex: 0 0 auto;
                min-height: 20px;
                padding: 14px 8px;
                position: relative;
                input {
                    border-radius: 3px;
                    box-shadow: none;
                    font-weight: 600;
                    height: 20px;
                    margin: -4px 0;
                    max-height: 256px;
                    min-height: 20px;
                    padding: 6px 8px 6px 12px;
                    resize: none;
                    width: 100%;
                    max-width: 100%;
                    border: 0;
                }
            }
            &-footer {
                align-items: center;
                display: flex;
                flex-shrink: 0;
                justify-content: space-between;
                max-height: revert;
                min-height: revert;
                .btn {
                    border-radius: 8px;
                    display: block;
                    flex: 1 0 auto;
                    margin: 8px 0 8px 8px;
                    padding: 4px 8px 4px 6px;
                    position: relative;
                    text-decoration: none;
                    -webkit-user-select: none;
                    user-select: none;
                    font-size: 13px;
                }
            }
            &-cards {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                gap: 7px;
                justify-content: space-between;
                margin: 0 4px;
                min-height: 0;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 1px 4px 0;
                z-index: 1;
            }
            &-task {
                background-color: rgba($color-light-blue, 0.2);
                border-radius: 3px;
                box-shadow: $shadow-default;
                // color: var(--ds-text,#172b4d);
                cursor: pointer;
                display: block;
                max-width: 300px;
                min-height: 20px;
                position: relative;
                scroll-margin: 8px;
                z-index: 0;
                border-radius: 8px;
                flex-shrink: 0;
                margin-bottom: 1px;
                font-size: 14px;
                &:last-of-type {
                    margin-bottom: 10px;
                }
                &-detail {
                    overflow: hidden;
                    padding: 8px 8px 4px 12px;
                    position: relative;
                    z-index: 10;
                }
                &-title {
                    word-wrap: break-word;
                    clear: both;
                    color: var(--ds-text, #172b4d);
                    display: block;
                    margin: 0 0 6px;
                    overflow: hidden;
                    text-decoration: none;
                }
                &-labels {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 4px;
                    margin-bottom: 8px;
                }
                &-label {
                    display: inline-block;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-right: 0;
                    padding-left: 0;
                    min-width: 40px;
                    max-width: 40px;
                    height: 8px;
                    color: transparent;
                    background-color: var(--label-color, $primary);
                    border-radius: 4px;
                }
                &-members {
                    text-align: right;
                    .avatar {
                        margin-right: 4px;
                    }
                    font-size: .75rem;
                }
            }
        }
    }
}

.task-actions{
    .btn{
        font-size: 14px;
    }
}